import apiFetch from '@wordpress/api-fetch';
import moment from 'moment-timezone'

window.addEventListener( 'load', ( event ) => {
	// Set interval, based on the refresh setting
	// to refresh the feed
	setInterval( function () {
		unc_alerts_refresh( $ );
		//console.log('refresh');
	}, unc_alerts.refresh * 1000 );

	function unc_alerts_refresh() {
		apiFetch( {
			url: unc_alerts.status_url,
			method: 'GET',
		} ).then( ( res ) => {
			//console.log(res)
			if ( res?.status !== unc_alerts.status_on_load ) {
				//console.log( 'status has changed since load: ' + res?.status );
			}
			const alertDiv = document.getElementById( 'unc-alert' );

			//if the ID has changed, change the alert
			if ( res?.identifier !== unc_alerts.identifier ) {
				//console.log( res?.identifier + '!==' + unc_alerts.identifier)
				unc_alerts.identifier = res.identifier;

				apiFetch( {
					url: unc_alerts.refresh_url,
					method: 'GET',
				} ).then( ( res ) => {
					//console.log(res.cap)
					if ( res.cap?.info?.headline ) {
						//alertDiv.querySelector('.unc-alert-actype')
						alertDiv.querySelector( '.unc-alert-headline' ).innerHTML = res.cap.info.headline;
					}

					if ( res?.cap?.info?.parameter?.value ) {
						alertDiv.querySelector( '.unc-alert-actype' ).innerHTML = res.cap.info.parameter.value;
					}

					//const unixTimeZero = Date.parse(res.cap.sent);
					const capMoment = moment(res.cap.sent).tz('America/New_York');
					alertDiv.querySelector( 'time' ).setAttribute('datetime',res.cap.sent);
					alertDiv.querySelector( 'time .time' ).innerHTML = 'Last updated at ' + capMoment.format('h:mma');
					alertDiv.querySelector( 'time .date' ).innerHTML = '<em class="day">' + capMoment.format('dddd') + '</em>' + capMoment.format('MMMM Do, YYYY');
					
				} );
			}

			if ( res?.status === 'published' && ! document.body.classList.contains( 'unc-alerts-active' ) ) {
				console.log( 'we need to show the alert, it was hidden before' );
				document.body.classList.add( 'unc-alerts-active' );
				alertDiv.classList.add( 'is-active' );
				alertDiv.classList.remove( 'not-active' );
			}

			if ( res?.status === 'unpublished' && document.body.classList.contains( 'unc-alerts-active' ) ) {
				console.log( 'we need to hide the alert, it was shown before' );
				document.body.classList.remove( 'unc-alerts-active' );
				alertDiv.classList.add( 'not-active' );
				alertDiv.classList.remove( 'is-active' );
			}
		} );
	}
} );
